table {
	font-size: 16pt;
	font-weight: 800;
	button {
		margin-top: 30px;
		font-size: 16pt;
	}
}

.wrong {
	color: var(--word-wrong-color);
}

.printResult{
	font-size: 12pt;
}