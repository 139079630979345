.test {
	display: flex;
	flex-direction: column;
	/*justify-content: center;*/
	margin-top: 5rem;
	font-weight: 700;
	/*height: 100%;*/
	width: 90%;
	max-width: 55rem;
}
.timer {
	font-size: 21pt;
	margin: 5px;
	color: var(--hl-color);
}

.boxComplete{
	height: auto;
}
.boxPartial{
	height: 135px;
}
.box {
	
	font-size: 1.5rem;
    
	overflow: hidden;
	/*height: 135px;*/
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	user-select: none;
	.word {
		color: var(--word-color);
		position: relative;
		margin: 0 5px 2px;
		&.wrong {
			
			text-decoration: 2px underline var(--word-wrong-color);
			animation: shake 0.1s ease;
			@keyframes shake {
				0% {
					transform: translateX(5px);
				}
				50% {
					transform: translateX(-5px);
				}
				100% {
					transform: translateX(0);
				}
			}
		}
	}
	.typedWord {
		position: absolute;
		top: 0;
		left: 0;
	}
	.right {
		color: var(--word-right-color);
	}
	#active {
		position: relative;
		width: max-content;
	}
	span.wrong {
		color: var(--word-wrong-color) !important;
	}
	.extra {
		opacity: 0.6;
	}

	#caret {
		transition: left 0.1s ease;
		font-size: 2.5rem;
		margin-left: -12px;
		margin-top: -13px;
		position: absolute;
		color: var(--caret-color);
		&.blink {
			animation: blink 1.5s infinite 1s;
		}
	}
	@keyframes blink {
		0%,
		100% {
			opacity: 1;
		}
		50% {
			opacity: 0;
		}
	}
}
