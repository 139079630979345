

header,
footer {
	margin: 30px 0;
	display: flex;
	width: 90%;
	max-width: 55rem;
	justify-content: space-between;
	align-items: center;
	font-size: 12pt;
	font-weight: 700;
	.buttons {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}
}

.brand {
	font-size: 21pt;
	align-self: center;
}

.modal-backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.4);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
}

.textModalDiv {
	height: 200px;
	overflow: scroll;
	overflow-x: hidden;
}
.infoModalDiv {
	width: 33rem;
    padding: 5px;
    background: var(--bg-color);
    color: var(--font-color);
	font-weight: bold;
}
.infoModalIcon{
	width: 10rem;
	margin:auto;
}