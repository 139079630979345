.light{
	/* colors */
	--word-wrong-color: #ca4754 !important;
	--word-color: #646669 !important;
	--word-right-color: #d1d0c5 !important;
	--caret-color: #646669 !important;

	--bg-color: #ffffff !important;
	--font-color: #111111 !important;
	--hl-color: orange !important;
	--fg-color: #292f34 !important;
}
.dark {
	/* colors */
	--bg-color: #323437 !important;
	--font-color: #d1d0c5 !important; 
	--hl-color: orange !important;
	--fg-color: #292f34 !important;
	--word-wrong-color: #ca4754 !important;
	--word-color: #646669 !important;
	--word-right-color: #d1d0c5 !important;
	--caret-color: #d1d0c5 ;
}
.mkbhd {
	--bg-color: #171717 !important;
	--font-color: #ededed !important;
	--hl-color: #da0037 !important;
	--fg-color: #444444 !important;
}

.mocha {
	--bg-color: #2d2424 !important;
	--font-color: #e0c097 !important;
	--hl-color: #dc521c !important;
	--fg-color: #5c3d2e !important;
}

.coral {
	--bg-color: #fdd2bf !important;
	--font-color: #df5e5e !important;
	--hl-color: #492f10 !important;
	--fg-color: #e98580 !important;
}

.ocean {
	--bg-color: #dddddd !important;
	--font-color: #125d98 !important;
	--hl-color: #f5a962 !important;
	--fg-color: #3c8dad !important;
}

.azure {
	--bg-color: #383e56 !important;
	--font-color: #eedad1 !important;
	--hl-color: #f69e7b !important;
	--fg-color: #d4b5b0 !important;
}

.forest {
	--bg-color: #334443 !important;
	--font-color: #fff8df !important;
	--hl-color: #c6ffc1 !important;
	--fg-color: #34656d !important;
}

.rose-milk {
	--bg-color: #ffffff !important;
	--font-color: #111111 !important;
	--hl-color: #b33838 !important;
	--fg-color: #3b8792 !important;
}
